import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import MainContents from './components/MainContents';
import MainHeader from './components/MainHeader';
import HomeContents from './components/HomeContents';

import './static/css/App.css';
import './static/css/Common.css'
import './static/css/MainContent.css';

export default function App() {
  return (
    <>

      <div style={{ fontSize:25 }}>기분 어떠신가요?</div>
      {/* <MainHeader/>
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeContents/>}/>
          <Route path="/personnelManagement" element={null}/>
        </Routes>
      </BrowserRouter> */}
    </>
  );
}